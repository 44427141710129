import revive_payload_client_961CajEya1 from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AgOvmJVtGc from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3gnWPBcney from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fj8G6KkQmj from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_a2qtcnr25gemd5eleucvsun4ce/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_sqT0e8cfzs from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_XY9i5CYLZJ from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_oBHf3ipiwu from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_KiqROuIskY from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.29/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_WLoPAdR1zb from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_@opentelemetry+api@1.9.0_encoding@0.1.13_magicast@0.3.4_next-auth@4_zbulf4cunvpnpdocldabqoipt4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_3K9tZAiwdz from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import click_outside_D2NiLGp7dJ from "/app/plugins/click-outside.ts";
import fontawesome_cn2c4tOOHz from "/app/plugins/fontawesome.ts";
import routerScrollBehavior_client_EBqXUtASto from "/app/plugins/routerScrollBehavior.client.ts";
import toastification_xLadjaQwvu from "/app/plugins/toastification.ts";
export default [
  revive_payload_client_961CajEya1,
  unhead_AgOvmJVtGc,
  router_3gnWPBcney,
  _0_siteConfig_fj8G6KkQmj,
  payload_client_sqT0e8cfzs,
  check_outdated_build_client_XY9i5CYLZJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oBHf3ipiwu,
  i18n_KiqROuIskY,
  plugin_WLoPAdR1zb,
  chunk_reload_client_3K9tZAiwdz,
  click_outside_D2NiLGp7dJ,
  fontawesome_cn2c4tOOHz,
  routerScrollBehavior_client_EBqXUtASto,
  toastification_xLadjaQwvu
]