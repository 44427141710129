import type {$Fetch} from "nitropack";

export function useCharon(auth: boolean = false): $Fetch {
  const config = useRuntimeConfig()
  const charon = $fetch.create({
    baseURL: `${config.public.charonUrl}`,
  })
  // unauthenticated
  if (!auth) {
    return charon
  }
  // authenticated
  return charon.create({
    // injects bearer token if user is authenticated
    async onRequest(ctx): Promise<void> {
      console.debug(`[charon]: request`, ctx.request)
      // only clients can be authenticated
      if (process.server) {
        console.error("[charon]: tried to call charon in authenticated mode on server: authenticated mode is only available in browser")
        return
      }
      // refresh access token if necessary
      const { data, getSession } = useAuth()
      if (data.value?.tokenExpiresAt && Math.floor(Date.now() / 1000) > data.value?.tokenExpiresAt - 5) {
        console.debug(`[charon]: triggering session refresh for request`, ctx.request)
        await getSession()
      }
      const token = data.value?.accessToken
      if (!token) {
        console.error(`[charon]: failed to retrieve token for charon authenticated mode`)
        return
      }
      const headers = {
        'Authorization': `Bearer ${token}`
      }
      ctx.options.headers = {...headers,  ...ctx.options.headers }
    }
  })
}
