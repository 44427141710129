<template>
  <div
    class="text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900"
  >
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useMoment } from "~/composables/useMoment";

const { locale } = useI18n()
const { moment } = useMoment()
const route = useRoute()
const { t } = useI18n()
const config = useRuntimeConfig()

// update local setting in shared moment.js instance with i18n locale
console.debug('[moment]: applying initial locale', locale.value);
moment.locale(locale.value)
watch(locale, () => {
  console.debug('[moment]: applying locale', locale.value);
  moment.locale(locale.value)
});

// update head (with localization)
const localeHead = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: {
    canonicalQueries: route.meta?.canonicalQueries || []
  }
})
useHead((() => {
  const nameKey = String(route.meta?.nameKey || route.meta.nameKey || 'fallback')

  const title = t(`jc.head.${nameKey}.title`)
  const description = t(`jc.head.${nameKey}.description`)
  //@ts-ignore
  const imageUrl = String(route.meta?.head?.imageUrl || '/images/technical/og_fallback.png')

  return {
    htmlAttrs: {
      ...localeHead.value.htmlAttrs!
    },
    link: [
      ...(localeHead.value.link || []),
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon.png'
      }
    ],
    titleTemplate: `%s – JustChunks`,
    title: title,
    meta: [
      ...(localeHead.value.meta || []),
      { name: 'theme-color', content: '#24be62' },
      { name: 'description', content: description },
      // Open Graph Protocol
      { name: 'og:site_name', content: `JustChunks` },
      { name: 'og:title', content: title },
      { name: 'og:description', content: description },
      { name: 'og:type', content: route.meta?.ogType as string || 'website' },
      { name: 'og:image', content: imageUrl },
      // Twitter Card
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: imageUrl },
      { name: 'twitter:card', content: 'summary_large_image' },
      // Facebook
      { name: 'fb:app_id', content: config.public.facebookAppId }
    ]
  }
}))
</script>
