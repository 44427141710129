import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as brandinguAW0Z6oSDfMeta } from "/app/pages/branding.vue?macro=true";
import { default as creatorhTHh4LPFhBMeta } from "/app/pages/creator.vue?macro=true";
import { default as clashlandsBtj8LXvhVJMeta } from "/app/pages/game/clashlands.vue?macro=true";
import { default as dungeon_45explorerWmUMDI8iO9Meta } from "/app/pages/game/dungeon-explorer.vue?macro=true";
import { default as indexRvgzTXZOtMMeta } from "/app/pages/game/index.vue?macro=true";
import { default as gameAUIqB7YSRXMeta } from "/app/pages/game.vue?macro=true";
import { default as helpgX3yoxbmc4Meta } from "/app/pages/help.vue?macro=true";
import { default as notice9889C9aQ5vMeta } from "/app/pages/legal/notice.vue?macro=true";
import { default as privacyE8rEcGUKW8Meta } from "/app/pages/legal/privacy.vue?macro=true";
import { default as termsiDH5moXFcLMeta } from "/app/pages/legal/terms.vue?macro=true";
import { default as editudioGSCnTaMeta } from "/app/pages/news/[news]/edit.vue?macro=true";
import { default as index9lFZhOO3gFMeta } from "/app/pages/news/[news]/index.vue?macro=true";
import { default as create0VhYVwsxH3Meta } from "/app/pages/news/create.vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as audio_45designersxvJGkiHU8Meta } from "/app/pages/participate/audio-designer.vue?macro=true";
import { default as builderC6iLkoX5KMMeta } from "/app/pages/participate/builder.vue?macro=true";
import { default as editorCfA38qIzxfMeta } from "/app/pages/participate/editor.vue?macro=true";
import { default as game_45designerf1F1eGaCVrMeta } from "/app/pages/participate/game-designer.vue?macro=true";
import { default as game_45developer5gV9DnOAfOMeta } from "/app/pages/participate/game-developer.vue?macro=true";
import { default as graphic_45artistog0OHVTkk2Meta } from "/app/pages/participate/graphic-artist.vue?macro=true";
import { default as indexxc0qvGjD87Meta } from "/app/pages/participate/index.vue?macro=true";
import { default as infra_45developernnIzSaB6IwMeta } from "/app/pages/participate/infra-developer.vue?macro=true";
import { default as promoterOn2TTHsR13Meta } from "/app/pages/participate/promoter.vue?macro=true";
import { default as supporterC3cniFbdlFMeta } from "/app/pages/participate/supporter.vue?macro=true";
import { default as video_45producerCXnmBUJmkuMeta } from "/app/pages/participate/video-producer.vue?macro=true";
import { default as web_45developer8EPg52j9M7Meta } from "/app/pages/participate/web-developer.vue?macro=true";
import { default as participatehPgvUrM1ISMeta } from "/app/pages/participate.vue?macro=true";
import { default as rulesCVy0F1SzKVMeta } from "/app/pages/rules.vue?macro=true";
import { default as shopjA8q7oHQTMMeta } from "/app/pages/shop.vue?macro=true";
import { default as teamxXW0gCJtE8Meta } from "/app/pages/team.vue?macro=true";
import { default as _91user_9364AH1Mi9RdMeta } from "/app/pages/user/[user].vue?macro=true";
import { default as indexC5fV0gYHokMeta } from "/app/pages/user/index.vue?macro=true";
import { default as userCXh5rkXRCzMeta } from "/app/pages/user.vue?macro=true";
import { default as component_45stubrpNIRsS5I1Meta } from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrpNIRsS5I1 } from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_ognwb4qtkoup3h6c5uvvr65kgu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "branding___en",
    path: "/en/branding",
    meta: brandinguAW0Z6oSDfMeta || {},
    component: () => import("/app/pages/branding.vue").then(m => m.default || m)
  },
  {
    name: "branding___de",
    path: "/de/branding",
    meta: brandinguAW0Z6oSDfMeta || {},
    component: () => import("/app/pages/branding.vue").then(m => m.default || m)
  },
  {
    name: "creator___en",
    path: "/en/creator",
    meta: creatorhTHh4LPFhBMeta || {},
    component: () => import("/app/pages/creator.vue").then(m => m.default || m)
  },
  {
    name: "creator___de",
    path: "/de/creator",
    meta: creatorhTHh4LPFhBMeta || {},
    component: () => import("/app/pages/creator.vue").then(m => m.default || m)
  },
  {
    name: gameAUIqB7YSRXMeta?.name,
    path: "/en/game",
    component: () => import("/app/pages/game.vue").then(m => m.default || m),
    children: [
  {
    name: "game-clashlands___en",
    path: "clashlands",
    meta: clashlandsBtj8LXvhVJMeta || {},
    component: () => import("/app/pages/game/clashlands.vue").then(m => m.default || m)
  },
  {
    name: "game-dungeon-explorer___en",
    path: "dungeon-explorer",
    meta: dungeon_45explorerWmUMDI8iO9Meta || {},
    component: () => import("/app/pages/game/dungeon-explorer.vue").then(m => m.default || m)
  },
  {
    name: "game___en",
    path: "",
    meta: indexRvgzTXZOtMMeta || {},
    component: () => import("/app/pages/game/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: gameAUIqB7YSRXMeta?.name,
    path: "/de/game",
    component: () => import("/app/pages/game.vue").then(m => m.default || m),
    children: [
  {
    name: "game-clashlands___de",
    path: "clashlands",
    meta: clashlandsBtj8LXvhVJMeta || {},
    component: () => import("/app/pages/game/clashlands.vue").then(m => m.default || m)
  },
  {
    name: "game-dungeon-explorer___de",
    path: "dungeon-explorer",
    meta: dungeon_45explorerWmUMDI8iO9Meta || {},
    component: () => import("/app/pages/game/dungeon-explorer.vue").then(m => m.default || m)
  },
  {
    name: "game___de",
    path: "",
    meta: indexRvgzTXZOtMMeta || {},
    component: () => import("/app/pages/game/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help___en",
    path: "/en/help",
    meta: helpgX3yoxbmc4Meta || {},
    component: () => import("/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___de",
    path: "/de/help",
    meta: helpgX3yoxbmc4Meta || {},
    component: () => import("/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-notice___en",
    path: "/en/legal/notice",
    meta: notice9889C9aQ5vMeta || {},
    component: () => import("/app/pages/legal/notice.vue").then(m => m.default || m)
  },
  {
    name: "legal-notice___de",
    path: "/de/legal/notice",
    meta: notice9889C9aQ5vMeta || {},
    component: () => import("/app/pages/legal/notice.vue").then(m => m.default || m)
  },
  {
    name: "legal-privacy___en",
    path: "/en/legal/privacy",
    meta: privacyE8rEcGUKW8Meta || {},
    component: () => import("/app/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: "legal-privacy___de",
    path: "/de/legal/privacy",
    meta: privacyE8rEcGUKW8Meta || {},
    component: () => import("/app/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: "legal-terms___en",
    path: "/en/legal/terms",
    meta: termsiDH5moXFcLMeta || {},
    component: () => import("/app/pages/legal/terms.vue").then(m => m.default || m)
  },
  {
    name: "legal-terms___de",
    path: "/de/legal/terms",
    meta: termsiDH5moXFcLMeta || {},
    component: () => import("/app/pages/legal/terms.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name,
    path: "/en/news",
    component: () => import("/app/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-news-edit___en",
    path: ":news()/edit",
    meta: editudioGSCnTaMeta || {},
    component: () => import("/app/pages/news/[news]/edit.vue").then(m => m.default || m)
  },
  {
    name: "news-news___en",
    path: ":news()",
    meta: index9lFZhOO3gFMeta || {},
    component: () => import("/app/pages/news/[news]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-create___en",
    path: "create",
    meta: create0VhYVwsxH3Meta || {},
    component: () => import("/app/pages/news/create.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "",
    meta: indexoxV4GRc61hMeta || {},
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newsWdQv0BaSvYMeta?.name,
    path: "/de/news",
    component: () => import("/app/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: "news-news-edit___de",
    path: ":news()/edit",
    meta: editudioGSCnTaMeta || {},
    component: () => import("/app/pages/news/[news]/edit.vue").then(m => m.default || m)
  },
  {
    name: "news-news___de",
    path: ":news()",
    meta: index9lFZhOO3gFMeta || {},
    component: () => import("/app/pages/news/[news]/index.vue").then(m => m.default || m)
  },
  {
    name: "news-create___de",
    path: "create",
    meta: create0VhYVwsxH3Meta || {},
    component: () => import("/app/pages/news/create.vue").then(m => m.default || m)
  },
  {
    name: "news___de",
    path: "",
    meta: indexoxV4GRc61hMeta || {},
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: participatehPgvUrM1ISMeta?.name,
    path: "/en/participate",
    component: () => import("/app/pages/participate.vue").then(m => m.default || m),
    children: [
  {
    name: "participate-audio-designer___en",
    path: "audio-designer",
    meta: audio_45designersxvJGkiHU8Meta || {},
    component: () => import("/app/pages/participate/audio-designer.vue").then(m => m.default || m)
  },
  {
    name: "participate-builder___en",
    path: "builder",
    meta: builderC6iLkoX5KMMeta || {},
    component: () => import("/app/pages/participate/builder.vue").then(m => m.default || m)
  },
  {
    name: "participate-editor___en",
    path: "editor",
    meta: editorCfA38qIzxfMeta || {},
    component: () => import("/app/pages/participate/editor.vue").then(m => m.default || m)
  },
  {
    name: "participate-game-designer___en",
    path: "game-designer",
    meta: game_45designerf1F1eGaCVrMeta || {},
    component: () => import("/app/pages/participate/game-designer.vue").then(m => m.default || m)
  },
  {
    name: "participate-game-developer___en",
    path: "game-developer",
    meta: game_45developer5gV9DnOAfOMeta || {},
    component: () => import("/app/pages/participate/game-developer.vue").then(m => m.default || m)
  },
  {
    name: "participate-graphic-artist___en",
    path: "graphic-artist",
    meta: graphic_45artistog0OHVTkk2Meta || {},
    component: () => import("/app/pages/participate/graphic-artist.vue").then(m => m.default || m)
  },
  {
    name: "participate___en",
    path: "",
    meta: indexxc0qvGjD87Meta || {},
    component: () => import("/app/pages/participate/index.vue").then(m => m.default || m)
  },
  {
    name: "participate-infra-developer___en",
    path: "infra-developer",
    meta: infra_45developernnIzSaB6IwMeta || {},
    component: () => import("/app/pages/participate/infra-developer.vue").then(m => m.default || m)
  },
  {
    name: "participate-promoter___en",
    path: "promoter",
    meta: promoterOn2TTHsR13Meta || {},
    component: () => import("/app/pages/participate/promoter.vue").then(m => m.default || m)
  },
  {
    name: "participate-supporter___en",
    path: "supporter",
    meta: supporterC3cniFbdlFMeta || {},
    component: () => import("/app/pages/participate/supporter.vue").then(m => m.default || m)
  },
  {
    name: "participate-video-producer___en",
    path: "video-producer",
    meta: video_45producerCXnmBUJmkuMeta || {},
    component: () => import("/app/pages/participate/video-producer.vue").then(m => m.default || m)
  },
  {
    name: "participate-web-developer___en",
    path: "web-developer",
    meta: web_45developer8EPg52j9M7Meta || {},
    component: () => import("/app/pages/participate/web-developer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: participatehPgvUrM1ISMeta?.name,
    path: "/de/participate",
    component: () => import("/app/pages/participate.vue").then(m => m.default || m),
    children: [
  {
    name: "participate-audio-designer___de",
    path: "audio-designer",
    meta: audio_45designersxvJGkiHU8Meta || {},
    component: () => import("/app/pages/participate/audio-designer.vue").then(m => m.default || m)
  },
  {
    name: "participate-builder___de",
    path: "builder",
    meta: builderC6iLkoX5KMMeta || {},
    component: () => import("/app/pages/participate/builder.vue").then(m => m.default || m)
  },
  {
    name: "participate-editor___de",
    path: "editor",
    meta: editorCfA38qIzxfMeta || {},
    component: () => import("/app/pages/participate/editor.vue").then(m => m.default || m)
  },
  {
    name: "participate-game-designer___de",
    path: "game-designer",
    meta: game_45designerf1F1eGaCVrMeta || {},
    component: () => import("/app/pages/participate/game-designer.vue").then(m => m.default || m)
  },
  {
    name: "participate-game-developer___de",
    path: "game-developer",
    meta: game_45developer5gV9DnOAfOMeta || {},
    component: () => import("/app/pages/participate/game-developer.vue").then(m => m.default || m)
  },
  {
    name: "participate-graphic-artist___de",
    path: "graphic-artist",
    meta: graphic_45artistog0OHVTkk2Meta || {},
    component: () => import("/app/pages/participate/graphic-artist.vue").then(m => m.default || m)
  },
  {
    name: "participate___de",
    path: "",
    meta: indexxc0qvGjD87Meta || {},
    component: () => import("/app/pages/participate/index.vue").then(m => m.default || m)
  },
  {
    name: "participate-infra-developer___de",
    path: "infra-developer",
    meta: infra_45developernnIzSaB6IwMeta || {},
    component: () => import("/app/pages/participate/infra-developer.vue").then(m => m.default || m)
  },
  {
    name: "participate-promoter___de",
    path: "promoter",
    meta: promoterOn2TTHsR13Meta || {},
    component: () => import("/app/pages/participate/promoter.vue").then(m => m.default || m)
  },
  {
    name: "participate-supporter___de",
    path: "supporter",
    meta: supporterC3cniFbdlFMeta || {},
    component: () => import("/app/pages/participate/supporter.vue").then(m => m.default || m)
  },
  {
    name: "participate-video-producer___de",
    path: "video-producer",
    meta: video_45producerCXnmBUJmkuMeta || {},
    component: () => import("/app/pages/participate/video-producer.vue").then(m => m.default || m)
  },
  {
    name: "participate-web-developer___de",
    path: "web-developer",
    meta: web_45developer8EPg52j9M7Meta || {},
    component: () => import("/app/pages/participate/web-developer.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "rules___en",
    path: "/en/rules",
    meta: rulesCVy0F1SzKVMeta || {},
    component: () => import("/app/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "rules___de",
    path: "/de/rules",
    meta: rulesCVy0F1SzKVMeta || {},
    component: () => import("/app/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/en/shop",
    meta: shopjA8q7oHQTMMeta || {},
    component: () => import("/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "shop___de",
    path: "/de/shop",
    meta: shopjA8q7oHQTMMeta || {},
    component: () => import("/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: "team___en",
    path: "/en/team",
    meta: teamxXW0gCJtE8Meta || {},
    component: () => import("/app/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "team___de",
    path: "/de/team",
    meta: teamxXW0gCJtE8Meta || {},
    component: () => import("/app/pages/team.vue").then(m => m.default || m)
  },
  {
    name: userCXh5rkXRCzMeta?.name,
    path: "/en/user",
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-user___en",
    path: ":user()",
    meta: _91user_9364AH1Mi9RdMeta || {},
    component: () => import("/app/pages/user/[user].vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "",
    component: () => import("/app/pages/user/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userCXh5rkXRCzMeta?.name,
    path: "/de/user",
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-user___de",
    path: ":user()",
    meta: _91user_9364AH1Mi9RdMeta || {},
    component: () => import("/app/pages/user/[user].vue").then(m => m.default || m)
  },
  {
    name: "user___de",
    path: "",
    component: () => import("/app/pages/user/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubrpNIRsS5I1Meta?.name,
    path: "/security.txt",
    component: component_45stubrpNIRsS5I1
  },
  {
    name: component_45stubrpNIRsS5I1Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubrpNIRsS5I1
  }
]