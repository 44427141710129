// noinspection JSUnusedGlobalSymbols
// noinspection JSUnusedGlobalSymbols
import type {JSONContent} from "@tiptap/core";
import type {Icon, Image} from "~/utils/types";

// <editor-fold desc="base">
export type ObjectId = string
export type Uuid = string
export type Instant = string

export function isObjectId(value?: string): boolean {
  return !!value && !!value.match(/^[0-9a-fA-F]{24}$/)
}
export function isUUID(value?: string): boolean {
  return !!value && !!value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
}
export function isMinecraftUsername(value?: string): boolean {
  return !!value && !!value.match(/^[a-zA-Z0-9_]{2,16}$/i)
}
export function isLegacyUsername(value?: string): boolean {
  return !!value && !!value.match(/^[0-9]+-.{0,16}$/i)
}
//</editor-fold>


export enum DepartmentKey {
  MANAGEMENT = 'MANAGEMENT',
  DEVELOPMENT = 'DEVELOPMENT',
  CREATIVE = 'CREATIVE',
  PROMOTION = 'PROMOTION',
  SUPPORT = 'SUPPORT',
  PLAYER = 'PLAYER',
  CREATOR = 'CREATOR',
  PREMIUM = 'PREMIUM',
  TECHNICAL = 'TECHNICAL',
}

/**
 * The {@link #UserRoleKey} is a reflection of the minecraft roles. They are used by charon (JSON-API) as strings.
 */
export enum UserRoleKey {
  PLAYER = 'PLAYER',
  PREMIUM = 'PREMIUM',

  CREATOR = 'CREATOR',

  BUILDER = 'BUILDER',
  GAME_DESIGNER = 'GAME_DESIGNER',
  VIDEO_PRODUCER = 'VIDEO_PRODUCER',
  GRAPHIC_ARTIST = 'GRAPHIC_ARTIST',
  AUDIO_DESIGNER = 'AUDIO_DESIGNER',

  WEB_DEVELOPER = 'WEB_DEVELOPER',
  GAME_DEVELOPER = 'GAME_DEVELOPER',
  INFRA_DEVELOPER = 'INFRA_DEVELOPER',

  EDITOR = 'EDITOR',
  PROMOTER = 'PROMOTER',

  SUPPORTER = 'SUPPORTER',
  MODERATOR = 'MODERATOR',

  GAMEMASTER = 'GAMEMASTER',
  MANAGER = 'MANAGER',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export enum ClanMemberRank {
  // noinspection JSUnusedGlobalSymbols
  EXTERNAL = "EXTERNAL",
  RECRUIT = "RECRUIT",
  LIEUTENANT = "LIEUTENANT",
  COLONEL = "COLONEL",
}

export interface User {
  id: Uuid
  name: string
  roles: UserRoleKey[] | null
  globalFirstJoinMoment: Instant | null
  globalPreviousPlaytime: number | null
  globalLastQuitMoment: Instant | null
  experience: number | null
  level: number | null
  online: boolean
  muted: boolean
  banned: boolean
  team: boolean
  premium: boolean
}

export interface UserClashlands {
  // user
  id: Uuid
  name: string
  // clashlands
  clan: Uuid
  memberRank: ClanMemberRank | null
  combatPoints: number | null
  warriorLevel: number | null
  serverFirstJoinMoment: Instant
  serverPreviousPlaytime: number
}

export interface UserDungeon {
  // user
  id: Uuid
  name: string
  // dungeon
  progress: Record<string, string> | null
  endlessProgress: Record<string, number> | null
  serverFirstJoinMoment: Instant
  serverPreviousPlaytime: number
}

export interface UserVote {
  // user
  id: Uuid
  name: string
  // vote
  votes: number | null
  streak:  number | null
  maxStreak: number | null
}

export interface Clan {
  id: Uuid
  name: string
  tag: string
  establishmentMoment: Instant
  founderId: Uuid
  members: Uuid[]
  elo: number | null
  combatPoints: number | null
  level: number
}

export interface DungeonEnvironment {
  id: ObjectId
  identifier: string
  index: number
}

export interface FileReference {
  id: ObjectId | null
  createDate: Date | null
  description: string
  original: FileVariant
  // only visible if file write permission
  edited: FileVariant | null
  editData: Record<string, any> | null
}

export interface FileVariant {
  createDate: Date | null
  mediaType: string | null
  extension: string | null
}

export interface SitemapUser {
  id: Uuid
  globalLastQuitMoment: Instant | null
}

export interface SitemapNews {
  slug: string
  title: string
  updateDate: Instant
  publishDate: Instant
  image: ObjectId | null
  images: ObjectId[]
  videos: ObjectId[]
}

export interface ServerVersion {
  protocol: number
  displayName: string
  supportedVersions: string[]
}

export enum NewsType {
  // noinspection JSUnusedGlobalSymbols
  WEEKLY_REVIEW = 'WEEKLY_REVIEW',
  BLOG = 'BLOG',
  CHANGELOG = 'CHANGELOG',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export interface News {
  id: ObjectId | null
  createDate: Instant | null
  updateDate: Instant | null
  slug: string
  type: NewsType
  tags: ObjectId[]
  image: ObjectId | null
  publishDate: Instant | null
  draft: boolean
  published: boolean | null
  title: JSONContent // TipTap content
  teaser: JSONContent | null // TipTap content
  body: JSONContent // TipTap content
}

export type StringifiedNews = {
  id: ObjectId | null
  createDate: Instant | null
  updateDate: Instant | null
  slug: string
  type: NewsType
  tags: ObjectId[]
  image: ObjectId | null
  publishDate: Instant | null
  draft: boolean
  published: boolean | null
  title: string // TipTap content
  teaser: string | null // TipTap content
  body: string // TipTap content
}

export const enum TagNamespace {
  NEWS = 'NEWS'
}

export interface Tag {
  id: ObjectId
  namespace: TagNamespace
  label: string
  description: string
}

export function parseNews(raw: StringifiedNews): News {
  const { teaser, title, body, ...other } = raw
  return {
    teaser: teaser !== null ? JSON.parse(teaser) : null,
    title: JSON.parse(title),
    body: JSON.parse(body),
    ...other
  }
}

export function stringifyNews(news: News): StringifiedNews {
  const { teaser, title, body, ...other } = news
  return {
    teaser: JSON.stringify(teaser),
    title: JSON.stringify(title),
    body: JSON.stringify(body),
    ...other
  }
}

export type DepartmentType = {
  nameKey: string,
  icon?: Icon
}

export const Department: ({ [key in DepartmentKey]: DepartmentType}) = {
  [DepartmentKey.MANAGEMENT]: {
    nameKey: 'management',
    icon: 'landmark',
  },
  [DepartmentKey.DEVELOPMENT]: {
    nameKey: 'development',
    icon: 'gear',
  },
  [DepartmentKey.CREATIVE]: {
    nameKey: 'creative',
    icon: 'brush',
  },
  [DepartmentKey.PROMOTION]: {
    nameKey: 'promotion',
    icon: 'megaphone',
  },
  [DepartmentKey.SUPPORT]: {
    nameKey: 'support',
    icon: 'gavel',
  },
  [DepartmentKey.CREATOR]: {
    nameKey: 'premium',
    icon: 'crown',
  },
  [DepartmentKey.PREMIUM]: {
    nameKey: 'premium',
    icon: 'crown',
  },
  [DepartmentKey.PLAYER]: {
    nameKey: 'players',
    icon: 'user',
  },
  [DepartmentKey.TECHNICAL]: {
    nameKey: 'technical',
    icon: 'gears',
  },
}

// UserRoles

export type UserRoleType = {
  nameKey: string,
  routeName?: string,
  priority: number,
  department: DepartmentKey,
  skin?: Image
}

export const UserRole: Readonly<Record<UserRoleKey, UserRoleType>> = {
  [UserRoleKey.PLAYER]: {
    nameKey: 'player',
    priority: 0,
    department: DepartmentKey.PLAYER
  },
  [UserRoleKey.PREMIUM]: {
    nameKey: 'premium',
    priority: 1,
    department: DepartmentKey.PREMIUM
  },

  [UserRoleKey.CREATOR]: {
    nameKey: 'creator',
    priority: 2,
    department: DepartmentKey.CREATOR
  },

  [UserRoleKey.BUILDER]: {
    nameKey: 'builder',
    priority: 3,
    department: DepartmentKey.CREATIVE,
    routeName: 'participate-builder',
    skin: { src: "images/skin_renders/roles/builder.png" }
  },
  [UserRoleKey.GAME_DESIGNER]: {
    nameKey: 'gameDesigner',
    priority: 4,
    department: DepartmentKey.CREATIVE,
    routeName: 'participate-game-designer',
    skin: { src: "images/skin_renders/roles/questmaster.png" }
  },
  [UserRoleKey.VIDEO_PRODUCER]: {
    nameKey: 'videoProducer',
    priority: 5,
    department: DepartmentKey.CREATIVE,
    routeName: 'participate-video-producer',
    skin: { src: "images/skin_renders/roles/video_producer.png" }
  },
  [UserRoleKey.GRAPHIC_ARTIST]: {
    nameKey: 'graphicArtist',
    priority: 6,
    department: DepartmentKey.CREATIVE,
    routeName: 'participate-graphic-artist',
    skin: { src: "images/skin_renders/roles/graphic_artist.png" }
  },
  [UserRoleKey.AUDIO_DESIGNER]: {
    nameKey: 'audioDesigner',
    priority: 7,
    department: DepartmentKey.CREATIVE,
    routeName: 'participate-audio-designer',
    skin: { src: "images/skin_renders/roles/audio_designer.png" }
  },

  [UserRoleKey.WEB_DEVELOPER]: {
    nameKey: 'webDeveloper',
    priority: 8,
    department: DepartmentKey.DEVELOPMENT,
    routeName: 'participate-web-developer',
    skin: { src: "images/skin_renders/roles/web_developer.png" }
  },
  [UserRoleKey.GAME_DEVELOPER]: {
    nameKey: 'gameDeveloper',
    priority: 9,
    department: DepartmentKey.DEVELOPMENT,
    routeName: 'participate-game-developer',
    skin: { src: "images/skin_renders/roles/game_developer.png" }
  },
  [UserRoleKey.INFRA_DEVELOPER]: {
    nameKey: 'infraDeveloper',
    priority: 10,
    department: DepartmentKey.DEVELOPMENT,
    routeName: 'participate-infra-developer',
    skin: { src: "images/skin_renders/roles/infra_developer.png" }
  },

  [UserRoleKey.EDITOR]: {
    nameKey: 'editor',
    priority: 11,
    department: DepartmentKey.PROMOTION,
    routeName: 'participate-editor',
    skin: { src: "images/skin_renders/roles/editor.png" }
  },
  [UserRoleKey.PROMOTER]: {
    nameKey: 'promoter',
    priority: 12,
    department: DepartmentKey.PROMOTION,
    routeName: 'participate-promoter',
    skin: { src: "images/skin_renders/roles/pr_manager.png" }
  },

  [UserRoleKey.SUPPORTER]: {
    nameKey: 'supporter',
    priority: 13,
    department: DepartmentKey.SUPPORT,
    routeName: 'participate-supporter',
    skin: { src: "images/skin_renders/roles/supporter.png" }
  },
  [UserRoleKey.MODERATOR]: {
    nameKey: 'moderator',
    priority: 14,
    department: DepartmentKey.SUPPORT
  },

  [UserRoleKey.GAMEMASTER]: {
    nameKey: 'gamemaster',
    priority: 15,
    department: DepartmentKey.MANAGEMENT
  },
  [UserRoleKey.MANAGER]: {
    nameKey: 'manager',
    priority: 16,
    department: DepartmentKey.MANAGEMENT
  },
  [UserRoleKey.ADMINISTRATOR]: {
    nameKey: 'administrator',
    priority: 17,
    department: DepartmentKey.MANAGEMENT
  }
}

// noinspection JSUnusedGlobalSymbols
export function isTeamRole(role: UserRoleKey) {
  const department = UserRole[role].department
  return (department !== DepartmentKey.PLAYER) && (department !== DepartmentKey.TECHNICAL)
}
